import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as FirstVariant } from './svgs/variant-0.svg'
import { ReactComponent as SecondVariant } from './svgs/variant-1.svg'
import { ReactComponent as ThirdVariant } from './svgs/variant-2.svg'
import { ReactComponent as FourthVariant } from './svgs/variant-3.svg'
import { ReactComponent as FifthVariant } from './svgs/variant-4.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const types = {
  type1: FirstVariant,
  type2: SecondVariant,
  type3: ThirdVariant,
  type4: FourthVariant,
  type5: FifthVariant,
}

const Tetris = ({ className, type, theme, rotate }) => {
  const Component = types[type]
  
return (
  <div className={cx(css.Tetris, className, theme)}>
    <Component
      className={css.svg}
      style={{ transform: `rotate(${rotate}deg)` }}
    />
  </div>
  )
}

Tetris.defaultProps = {
  type: 'type1',
  theme: 'purple',
  rotate: 0,
}

export default Tetris
