import React, { cloneElement } from 'react'

import Iframe from 'components/shared/Iframe'
import NewCta from 'components/slices/NewCta'
import EmbedForm from 'components/slices/EmbedForm'

import css from './styles.module.scss'

const Html = ({ element }) => {
  switch (element.type) {
    case 'div':
      return <div className={css.iframe}>{element}</div>
    case 'cta':
      const { title, text, buttontext, buttonlink } = element.props
      if (title && text && buttontext && buttonlink) {
        return (
          <NewCta
            title={title}
            text={text}
            button={[
              {
                theme: 'blue',
                link: {
                  href: buttonlink,
                  text: buttontext,
                  icons: [],
                },
              },
            ]}
          ></NewCta>
        )
      } else {
        return <></>
      }

    case 'embed-form':
      const {
        id,
        'form-id': formId,
        'portal-id': portalId,
        'intro-theme': formIntroTheme,
        'intro-title': formIntroTitle,
        'intro-label': formIntroLabel,
        'intro-text': formIntroText,
        'gradient-header-theme': gradientHeaderTheme,
        'gradient-header-layout': gradientHeaderLayout,
        'gradient-header-decoration-type': gradientHeaderDecorationType,
      } = element.props

      if (formId && portalId) {
        return (
          <EmbedForm
            embedForm={{
              formId,
              portalId,
              id,
            }}
            intro={{
              theme: formIntroTheme,
              title: formIntroTitle,
              label: formIntroLabel,
              text: formIntroText,
            }}
            formLayout="mobileInnerWrapper"
            gradientHeader={{
              theme: gradientHeaderTheme,
              layout: gradientHeaderLayout,
              decorationType: gradientHeaderDecorationType,
            }}
          />
        )
      } else {
        return <></>
      }

    case 'iframe':
      return (
        <Iframe className={css.iframe}>
          {cloneElement(element, {
            // fix cookie-bot blocking
            // https://support.cookiebot.com/hc/en-us/articles/360003790854-Iframe-cookie-consent-with-YouTube-example
            'data-cookieconsent': 'marketing',
          })}
        </Iframe>
      )
    default:
      return element
  }
}

export default Html
