import React from 'react'
import classnames from 'classnames/bind'
import ContactForm from 'components/shared/ContactForm'
import Tetris from 'components/decorations/Tetris'
import Layout from 'components/shared/Layout'
import Triangle from 'components/shared/Triangle'
import delve from 'dlv'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const EmbedForm = ({ className, embedForm, intro, gradientHeader, formLayout = 'smallInnerWrapper'}) => {
  const theme = delve(gradientHeader, 'theme')
  const layout = delve(gradientHeader, 'layout')
  const decorationType = delve(gradientHeader, 'decorationType')

  return (
    <Layout className={cx(className, css.EmbedForm, layout)} type="maxWidth">
      <Layout type="wrapper">
        <Layout type={formLayout} className={css.smallInnerWrapper}>
          <ContactForm
            className={css.contactForm}
            formIntro={intro}
            {...embedForm}
          />
          {layout && theme && decorationType && (
            <Tetris
              className={css.tetris}
              rotate={layout === 'left' ? -90 : 180}
              theme={theme}
              type={decorationType}
            />
          )}
        </Layout>
      </Layout>
      <div className={css.triangle}>
        <Triangle type={layout === 'left' ? 'bottom-right' : 'bottom-left'} />
      </div>
    </Layout>
  )
}

EmbedForm.defaultProps = {
  layout: 'left',
  theme: 'blue',
  decorationType: 'type4',
}

export default EmbedForm
